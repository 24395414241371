declare global {
  interface Window {
    downtimeData: any;
  }
}

export default function initDowntimeListView() {
  const systemDowntimeContent = document.querySelector(
    '.system-downtime-content'
  );

  if (!systemDowntimeContent) return;

  try {
    if (!window.downtimeData) return;
    const pageDataUrl = window.downtimeData;
    fetch(pageDataUrl)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        });

        const listView = document.getElementById('listView');
        const listViewTable = listView.querySelector('table tbody');

        let currentMonth = '';
        data.forEach((item) => {
          const startDate = new Date(item.startDate);
          const month = startDate.toLocaleString('default', {
            month: 'long',
            timeZone: 'UTC',
          });
          const year = startDate.getFullYear();

          const impactedSystems = item.impact.reduce((acc, impact) => {
            return acc.concat(impact.systems);
          }, []);

          // Add a new row for each month
          if (month !== currentMonth) {
            const monthRow = document.createElement('tr');
            monthRow.innerHTML = `
                        <td colspan="4" class="month">${month} ${year}</td>
                      `;
            listViewTable.appendChild(monthRow);
            currentMonth = month;
          }

          const row = document.createElement('tr');
          row.innerHTML = `
                      <td data-title="From">
                        <span>${startDate.toLocaleDateString('en-US', {
                          weekday: 'long',
                          timeZone: 'UTC',
                        })}</span><br />
                        <strong>${startDate.toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        })}  ${item.startTime ? item.startTime : ''}</strong>
                      </td>
                      <td data-title="To">
                        <span>${new Date(item.endDate).toLocaleDateString(
                          'en-US',
                          {
                            weekday: 'long',
                            timeZone: 'UTC',
                          }
                        )}</span><br />
                        <strong>${new Date(item.endDate).toLocaleDateString(
                          'en-US',
                          {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC',
                          }
                        )} ${item.endTime ? item.endTime : ''}</strong>
                      </td>
                      <td data-title="Impacted Systems">
                        ${impactedSystems
                          .map((system) => `<strong>${system}</strong>`)
                          .join('<br />')}
                      </td>
                      <td data-title="Type">
                        <div class="tag tag-${
                          item.type === 'downtime' ? 'error' : 'primary'
                        }">
                          <i class="icon icon-${
                            item.type === 'downtime' ? 'clock' : 'wrench'
                          }"></i>
                          ${
                            item.type === 'downtime'
                              ? 'System Downtime'
                              : 'Maintenance Window'
                          }
                        </div>
                      </td>
                    `;
          listViewTable.appendChild(row);
        });
      });
  } catch (error) {
    console.error(error);
  }
}
