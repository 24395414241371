export function initSmoothScroll() {
  const smoothScrollLinks = document.querySelectorAll(
    'a[href^="#"]:not([href="#"])'
  );

  smoothScrollLinks.forEach((link) => {
    link.addEventListener('click', smoothScrollHandler);
  });

  function smoothScrollHandler(event) {
    if (event) event.preventDefault();
    const targetId = event ? this.getAttribute('href') : window.location.hash;
    performSmoothScroll(targetId);
  }

  function performSmoothScroll(targetId) {
    const targetElement = document.querySelector(targetId);

    if (!targetElement) return;

    const headerElement = document.querySelector('.main-header') as HTMLElement;
    const headerHeight = headerElement ? headerElement.offsetHeight : 140;

    let offset = headerHeight;
    let topOffset = 40;

    if (window.innerWidth < 992) {
      offset = headerHeight; // Adjust if you have specific mobile settings
      topOffset = 16;
    }

    const targetPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset - offset;

    targetElement.classList.add('focusOnClick');
    targetElement.setAttribute('tabindex', '-1');
    targetElement.focus({ preventScroll: true });

    window.scrollTo({
      top: targetPosition - topOffset,
      behavior: 'smooth',
    });
  }

  // Prevent automatic jump to hash on initial load
  if (window.location.hash) {
    const initialHash = window.location.hash;
    history.replaceState(null, null, ' '); // Temporarily remove the hash

    window.addEventListener('load', function () {
      setTimeout(() => {
        history.replaceState(null, null, initialHash); // Restore the hash
        performSmoothScroll(initialHash);
      }, 500);
    });
  }
}
