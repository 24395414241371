import Tab from './tab';

declare global {
  interface Window {
    downtimeData: any;
  }
}

export async function initCalendarView() {
  /* Get the calendar main div and set the current year*/
  const calendarBlock = document.getElementById('yearCalendar');
  let currentYear = new Date().getFullYear();

  const thisYearLabel = document.querySelector('label[for="this-year"]');
  if (thisYearLabel)
    thisYearLabel.innerHTML =
      '<i class="icon icon-check"></i> Current Year (' + currentYear + ')';

  const nextYearLabel = document.querySelector('label[for="next-year"]');
  if (nextYearLabel)
    nextYearLabel.innerHTML =
      '<i class="icon icon-check"></i> Next Year (' + (currentYear + 1) + ')';

  // Securely fetch event data from the JSON file in the public folder
  let events = [];
  try {
    if (!window.downtimeData) return;
    const pageDataUrl = window.downtimeData;
    const response = await fetch(pageDataUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok: ' + response.statusText);
    }
    events = await response.json();
  } catch (error) {
    console.error('Failed to fetch events:', error);
    // Optionally display an error message or default data
    return;
  }

  /* Generate month and days of the calendar */
  function generateMonthDays(year: number, month: number) {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    const daysInMonth = endDate.getDate();
    const startDayOfWeek = startDate.getDay();

    // Prepare an array with placeholders for previous month's days
    let daysArray = new Array(startDayOfWeek).fill(null);
    // Fill current month's days
    daysArray = daysArray.concat(
      [...Array(daysInMonth).keys()].map((i) => i + 1)
    );

    return daysArray;
  }

  /* Mark event as downtime or maintenance */
  function markEvents(monthDays: any[], month: number, year: number) {
    const formattedMonth = month + 1; // JavaScript months are 0-indexed
    monthDays = monthDays.map((day) => {
      if (!day) return { day: '', classes: '', hasEvent: false };

      const currentDate = `${year}-${formattedMonth
        .toString()
        .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      let eventClasses = '';
      // let eventDescription = '';
      let eventImpact = [];
      let eventType = '';
      let hasEvent = false;
      let isEventStart = false;
      let isEventEnd = false;

      events.forEach((event) => {
        if (currentDate >= event.startDate && currentDate <= event.endDate) {
          hasEvent = true;
          eventType = event.type;
          // eventDescription = event.description;
          // concat the new impact data into existing
          eventImpact = eventImpact.concat(event.impact);
          eventClasses =
            event.type === 'downtime' ? 'event-downtime' : 'event-maintainance';

          // Determine if this day is the start or end of an event
          if (currentDate === event.startDate) {
            isEventStart = true;
          }
          if (currentDate === event.endDate) {
            isEventEnd = true;
          }
        }
      });

      return {
        day,
        classes: eventClasses,
        // eventDescription,
        eventImpact,
        eventType,
        hasEvent,
        isEventStart,
        isEventEnd,
      };
      //return { day, classes: eventClasses, eventDescription, eventType, hasEvent };
    });

    return monthDays;
  }

  /* Main crete calendar functions */
  function createCalendar(year: number) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    document.getElementById('yearDisplay')!.innerText = year.toString(); // Update the year display

    let calendarHtml = '<div class="calendar-grid">';

    months.forEach((month, index) => {
      let monthDays = generateMonthDays(year, index);
      monthDays = markEvents(monthDays, index, year);

      calendarHtml += `
        <div class="calendar-month">
          <h3 class="calendar-month-title">${month}</h3>
          <div class="calendar-month-header">
            ${daysOfWeek
              .map(
                (day) =>
                  `<div class="calendar-day" aria-hidden="true">${day}</div>`
              )
              .join('')}
          </div>
          <div class="calendar-month-body">
            ${monthDays
              .map(
                (
                  {
                    day,
                    classes,
                    // eventDescription,
                    eventImpact,
                    eventType,
                    hasEvent,
                    isEventStart,
                    isEventEnd,
                  },
                  index
                ) => {
                  let additionalClasses = classes;
                  if (isEventStart) {
                    const previousDay = monthDays[index - 1];
                    if (!previousDay || !previousDay.hasEvent) {
                      additionalClasses += ' event-start';
                    }
                  }
                  if (isEventEnd) {
                    const nextDay = monthDays[index + 1];
                    if (!nextDay || !nextDay.hasEvent) {
                      additionalClasses += ' event-end';
                    }
                  }

                  // construct the description markup from the eventImpact data. here is what the data looks like:
                  // "impact": [
                  //   {
                  //     "date": "Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024  8 a.m.",
                  //     "systems": [
                  //       "Completion Status",
                  //       "Data Entry Interface",
                  //       "TDS/TA Interface"
                  //     ]
                  //   },
                  //   {
                  //     "date": "Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024",
                  //     "systems": ["TOMS"]
                  //   }
                  // ]

                  // here is what the description should look like:
                  // "<p><strong>Impacted Systems</strong><br>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024</p><p><strong>TOMS</strong></p>",

                  let eventDescription = '';
                  if (eventImpact) {
                    eventDescription = `<strong>Impacted Systems</strong>`;
                    eventDescription += eventImpact
                      .map((impact) => {
                        return `<p><span class="date">${
                          impact.date
                        }</span></p><p><strong>${impact.systems.join(
                          '<br>'
                        )}</strong></p>`;
                      })
                      .join('<hr style="margin-bottom: 12px;"/>');
                  }
                  const isMobile = window.innerWidth < 768;

                  return hasEvent
                    ? `<button popovertarget="popover-day-${
                        month + day
                      }" id="popover-day-${
                        month + day
                      }-button" class="${additionalClasses} event-day" title="${eventType
                        .charAt(0)
                        .toUpperCase()}${eventType.slice(
                        1
                      )} on ${month} ${day}" aria-expanded="false" aria-controls="popover-day-${
                        month + day
                      }">
                  <span aria-hidden="true">${day}</span>
                  </button>
                  <div class="calendar-popup" id="popover-day-${
                    month + day
                  }" popover role="note" ${isMobile ? '' : ''}>
                      <div class="calendar-popup-header">
                          <i class="${
                            eventType === 'downtime'
                              ? 'icon icon-clock'
                              : 'icon icon-wrench'
                          }"></i>
                          ${
                            eventType === 'downtime'
                              ? 'System Downtime'
                              : 'Maintenance Window'
                          }
                      </div>
                      <div class="calendar-popup-body">${eventDescription}</div>
                  </div>
                  `
                    : `<div class="${classes} event-day" aria-hidden="true">${day}</div>`;
                }
              )
              .join('')}
          </div>
        </div>
      `;
    });

    calendarHtml += '</div>';
    const calendarBlock = document.querySelector('#yearCalendar');
    if (calendarBlock) {
      calendarBlock.innerHTML = calendarHtml;
      // Post-render adjustment
      // document.querySelectorAll('.event-day').forEach((day) => {
      //   adjustPopupPosition(day);
      // });
    }

    setupKeyboardAccess();
  }

  /* Handle the click and tabbing events */
  function setupKeyboardAccess() {
    const eventDays = document.querySelectorAll('.event-day');
    const isMobile = window.innerWidth < 768;

    eventDays.forEach((day) => {
      // Handle keyboard 'Enter' and 'ESC' key
      // day.addEventListener('keydown', function (e: KeyboardEvent) {
      //   if (e.key === 'Enter') {
      //     e.preventDefault();
      //     // Check if the current popup is already active
      //     if (this.classList.contains('active')) {
      //       this.classList.remove('active'); // Close this popup if it's active
      //     } else {
      //       closeAllPopups(); // Close all other popups
      //       this.classList.add('active'); // Open this popup
      //     }
      //   } else if (e.key === 'Escape' || e.key === 'Esc') {
      //     e.preventDefault();
      //     this.classList.remove('active'); // Close this popup
      //   }
      // });
      // Handle mouse click event
      // day.addEventListener('click', function (e: MouseEvent) {
      //   e.preventDefault();
      //   if (this.classList.contains('active')) {
      //     this.classList.remove('active'); // Close this popup if it's active
      //   } else {
      //     closeAllPopups(); // Close all other popups
      //     this.classList.add('active'); // Open this popup
      //   }
      // });
      // if (!isMobile) {
      //   // Handle mouse hover to toggle popups
      //   day.addEventListener('mouseenter', function (e: MouseEvent) {
      //     closeAllPopupsExcept(this); // Close all other popups, except the current one
      //     this.classList.add('active');
      //   });
      //   // Optionally, handle mouse leave to close the popup
      //   day.addEventListener('mouseleave', function (e: MouseEvent) {
      //     this.classList.remove('active');
      //   });
      // }

      let timeout;
      let hideTimeout;

      const target = day.getAttribute('popovertarget');
      if (target) {
        const popover = document.querySelector('#' + target) as HTMLElement;

        if (!isMobile) {
          // day.addEventListener('mouseenter', () => {
          //   clearTimeout(hideTimeout);
          //   // delay opening
          //   timeout = setTimeout(() => {
          //     popover.showPopover();
          //   }, 300);
          // });

          // day.addEventListener('blur', () => {
          //   clearTimeout(timeout);
          //   hideTimeout = setTimeout(() => {
          //     popover.hidePopover();
          //   }, 300); // 500ms delay
          // });

          popover.addEventListener('toggle', (e: any) => {
            const button = document.getElementById(target + '-button');
            if (e.newState === 'open') {
              const dayRect = day.getBoundingClientRect();
              const popoverRect = popover.getBoundingClientRect();

              const popoverTop = dayRect.top - popoverRect.height - 10;
              const popoverLeft = dayRect.left;

              // Check if popover goes past the right edge of the viewport
              if (popoverLeft + popoverRect.width > window.innerWidth) {
                popover.style.left = `${dayRect.right - popoverRect.width}px`;
              } else {
                popover.style.left = `${popoverLeft}px`;
              }

              // Check if popover goes past the bottom edge of the viewport
              if (popoverTop < 0) {
                popover.style.top = `${dayRect.bottom + 10}px`;
              } else {
                popover.style.top = `${popoverTop}px`;
              }
              if (button) {
                button.setAttribute('aria-expanded', 'true');
              }

              popover.setAttribute('tabindex', '-1');
              popover.setAttribute('aria-live', 'assertive');
              popover.focus();
            } else {
              popover.style.top = '';
              popover.style.left = '';
              // find the button and set aria expanded to false

              if (button) {
                button.setAttribute('aria-expanded', 'false');
              }
            }
          });

          popover.addEventListener('blur', (e: any) => {
            if (e.relatedTarget && !popover.contains(e.relatedTarget)) {
              popover.hidePopover();
            }
          });

          // day.addEventListener('mouseleave', () => {
          //   // Clear any existing timeout to prevent multiple
          //   clearTimeout(hideTimeout);

          //   // Set a timeout to delay the hiding of the popover
          //   hideTimeout = setTimeout(() => {
          //     popover.hidePopover();
          //   }, 300); // 500ms delay
          // });
        }
      }
    });
  }

  /* Close all the popups */
  function closeAllPopups() {
    const activePopups = document.querySelectorAll('.event-day.active');
    activePopups.forEach((popup) => {
      popup.classList.remove('active');
    });
  }

  /* Close all the popup excent current */
  function closeAllPopupsExcept(currentElement: HTMLElement) {
    const activePopups = document.querySelectorAll('.event-day.active');
    activePopups.forEach((popup) => {
      if (popup !== currentElement) {
        popup.classList.remove('active');
      }
    });
  }

  /* Adjust the popup position */
  function adjustPopupPosition(element) {
    const popup = element.querySelector('.calendar-popup');
    if (!popup) return;

    // Start aligned to the left relative to the event-day
    popup.style.left = '0';
    popup.style.right = 'auto';

    const popupRect = popup.getBoundingClientRect();
    const container = document.getElementById('yearCalendar');
    const containerRect = container.getBoundingClientRect();

    // Check if the popup goes out of the bounds of the container on the right side
    if (popupRect.right > containerRect.right) {
      const overflowRight = popupRect.right - containerRect.right;
      popup.style.left = `auto`;
      popup.style.right = `0`;
    }
  }

  /* Run the calendar */
  if (calendarBlock) {
    /* Update the calender to the previous year */
    // document.getElementById('prevYear')?.addEventListener('click', (e) => {
    //   e.preventDefault(); // Prevent default action that might cause page jumps
    //   currentYear -= 1;
    //   createCalendar(currentYear);
    // });

    /* Update the calender to next year */

    let nextYearValue = currentYear + 1;
    document.getElementById('next-year')?.addEventListener('change', (e) => {
      // e.preventDefault(); // Prevent default action
      createCalendar(nextYearValue);
    });

    nextYearLabel?.addEventListener('keypress', (e: any) => {
      if (e.key !== 'Enter') return;
      document.getElementById('next-year')?.click();
    });

    /* Set the calendar to the current */
    document.getElementById('this-year')?.addEventListener('change', (e) => {
      // e.preventDefault(); // Prevent default action
      currentYear = new Date().getFullYear(); // Reset the year to current
      createCalendar(currentYear);
    });

    thisYearLabel?.addEventListener('keypress', (e: any) => {
      if (e.key !== 'Enter') return;
      document.getElementById('this-year')?.click();
    });
  }

  /* Adjust the Popup positon on Year Tab Nav button click */
  /* This is because by default its hidden inside the tab content */
  var tabElement = document.querySelector('#yearView-tab');
  if (tabElement) {
    tabElement.addEventListener('shown.bs.tab', function (event) {
      // Ensure the calendar is visible

      /* Create the calendar on Year Tab Show event */
      createCalendar(currentYear);
    });
  }

  /* Get all the tab items */
  var tabItems = document.querySelectorAll('[data-bs-toggle="tab"]');
  tabItems.forEach((tab) => {
    tab.addEventListener('click', function () {
      // Properly create a new URL object from the current location's href
      const url = new URL(window.location.href); // Use .href to get a string
      url.searchParams.set('activeTab', this.id);
      window.history.pushState({ tab: this.id }, '', url.toString()); // Ensure to convert URL object to string
      activateTab(this.id);
    });
  });

  /* Activate the tab ID from the query parameter URL */
  if (document.readyState === 'loading') {
    // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', () => {
      const params = new URLSearchParams(window.location.search);
      const activeTabId = params.get('activeTab');
      activateTab(activeTabId);
    });
  } else {
    // `DOMContentLoaded` has already fired
    const params = new URLSearchParams(window.location.search);
    const activeTabId = params.get('activeTab');
    activateTab(activeTabId);
  }

  /* Activate tab function */
  function activateTab(tabId) {
    const activeTab = document.getElementById(tabId);
    if (activeTab) {
      const tab = new Tab(activeTab);
      tab.show();

      if (tabId === 'yearView-tab' && calendarBlock) {
        // Programmatically click the "Today Year" button to reset the calendar
        const todayYearBtn = document.getElementById('todayYear');
        if (todayYearBtn) {
          todayYearBtn.click(); // This triggers any event listener attached to this button
        }
      }
    } else {
      // Optionally activate a default tab if no specific tab is requested
      const defaultTab = document.querySelector('[data-bs-toggle="tab"]');
      if (defaultTab) {
        const defaultTabInstance = new Tab(defaultTab);
        defaultTabInstance.show();
      }
    }
  }
}

/* Dummy event data */
// const events = [
//   { type: 'downtime', startDate: '2024-02-15', endDate: '2024-02-18', description: '<p><strong>Impacted Systems</strong><br>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2024-04-06', endDate: '2024-04-07', description: '<p>Sat, Apr 06, 2024 12 a.m. - Sun, Apr 07, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'downtime', startDate: '2024-04-13', endDate: '2024-04-14', description: '<p><strong>Impacted Systems</strong><br>Sat, Apr 13, 2024  5 p.m. - Sun, Apr 14, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Sat, Apr 13, 2024  5 p.m. - Sun, Apr 14, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2024-05-11', endDate: '2024-05-12', description: '<p>Sat, Aug 17, 2024 12 a.m. - Sat, Aug 18, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2024-06-08', endDate: '2024-06-09', description: '<p>Sat, Aug 17, 2024 12 a.m. - Sat, Aug 18, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'downtime', startDate: '2024-06-29', endDate: '2024-06-30', description: '<p><strong>Impacted Systems</strong><br>Thu, Jan 4, 2024  5 p.m. - Tue, Jan 9, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Fri, Jan 5, 2024  5 p.m. - Tue, Jan 9, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'downtime', startDate: '2024-07-01', endDate: '2024-07-02', description: '<p><strong>Impacted Systems</strong><br>Mon, July 1, 2024  5 p.m. - Tue, July 2, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Fri, Jan 5, 2024  5 p.m. - Tue, Jan 9, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'downtime', startDate: '2024-08-02', endDate: '2024-08-05', description: '<p><strong>Impacted Systems</strong><br>Fri, Aug 2, 2024  5 p.m. - Mon, Aug 5, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Fri, Aug 2, 2024  5 p.m. - Mon, Aug 5, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2024-08-17', endDate: '2024-08-18', description: '<p>Sat, July 17, 2024 12 a.m. - Sun, July 18, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2024-07-13', endDate: '2024-07-14', description: '<p>Sat, July 13, 2024 12 a.m. - Sun, July 14, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'downtime', startDate: '2024-09-14', endDate: '2024-09-16', description: '<p><strong>Impacted Systems</strong><br>Sat, Sep 14, 2024  5 p.m. - Mon, Sep 16, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Fri, Jan 5, 2024  5 p.m. - Tue, Jan 9, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2024-10-19', endDate: '2024-10-21', description: '<p>Sat, Oct 19, 2024 12 a.m. - Mon, Oct 21, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2024-11-16', endDate: '2024-11-17', description: '<p>Sat, Nov 16, 2024 12 a.m. - Sun, Nov 17, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2024-12-07', endDate: '2024-12-08', description: '<p>Sat, Dec 7, 2024 12 a.m. - Sun, Dec 8, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'downtime', startDate: '2025-01-10', endDate: '2025-01-13', description: '<p><strong>Impacted Systems</strong><br>Fri, Jan 10, 2024  5 p.m. - Mon, Jan 13, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Fri, Jan 10, 2024  5 p.m. - Mon, Jan 13, 2024  8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2025-02-08', endDate: '2025-02-09', description: '<p>Sat, Feb 8, 2024 12 a.m. - Sun, Feb 9, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2025-03-08', endDate: '2025-03-09', description: '<p>Sat, Mar 8, 2024 12 a.m. - Sun, Mar 9, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'downtime', startDate: '2025-04-12', endDate: '2025-04-13', description: '<p><strong>Impacted Systems</strong><br>Sat, Apr 10, 2024  5 p.m. - Sun, Apr 12, 2024 8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Sat, Apr 10, 2024  5 p.m. - Sun, Apr 12, 2024 8 a.m.</p><p><strong>TOMS</strong></p>' },
//   { type: 'maintenance', startDate: '2025-04-26', endDate: '2025-04-27', description: '<p>Sat, Apr 26, 2024 12 a.m. - Sun, Apr 27, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2025-05-10', endDate: '2025-05-11', description: '<p>Sat, May 10, 2024 12 a.m. - Sun, May 11, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' },
//   { type: 'maintenance', startDate: '2025-06-07', endDate: '2025-06-08', description: '<p>Sat, Jun 7, 2024 12 a.m. - Sun, Jun 8, 2024 12 a.m.</p><p><strong>Possible maintenance to system(s)</strong></p>' }
// ];
